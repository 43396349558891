// Models
import { AccountModel } from './account.model';
import { OrderModel } from './order.model';
import { TrainingModel } from './training.model';

export enum ProtocolAcceptance {
  NOT_SENT,
  SENT,
  ACCEPTED,
  REJECTED
}

export interface EditProtocolModel {
  order: string;
  protocolDevices: Array<{
    device: string;
    deviceName: string;
    contractNumber: string;
    softwareVersion: string;
  }>;
  agreementNumber: string;
  dwl: string;
  branch: string;
  attachments: string;
  notes: string;
  trainings: Array<number>;
  trainingNotes: string;
  givingPersonPhoneNumber: string;
  receivingPersonName: string;
  receivingPersonEmail: string;
  receivingPersonPhoneNumber: string;
}

export interface ProtocolModel {
  id: string;
  order: OrderModel;
  account: AccountModel;
  agreementNumber: string;
  dwl: string;
  branch: string;
  attachments: string;
  notes: string;
  trainings: Array<TrainingModel>;
  trainingNotes: string;
  givingPersonPhoneNumber: string;
  receivingPersonName: string;
  receivingPersonEmail: string;
  receivingPersonPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  acceptance: ProtocolAcceptance;
  acceptanceComment: string;
  acceptanceChangedAt: string;
}
