// Angular
import { HttpParams } from '@angular/common/http';
// Models
import { ApiDataModel, ApiDataType } from '../models';

export class HelperService {

  public static GetFormData(data: ApiDataModel): FormData {
    const formData = new FormData();

    const appendFormData = (formData: FormData, key: string, value: ApiDataType | ApiDataModel): void => {
      if (value === null || value === undefined) {
        return;
      }

      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (Array.isArray(item) || typeof item === 'object') {
            appendFormData(formData, `${key}[${index}]`, item);
          } else {
            formData.append(`${key}[${index}]`, `${item}`);
          }
        });
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([itemKey, itemValue]) => {
          appendFormData(formData, `${key}[${itemKey}]`, itemValue);
        });
      } else if (value === true) {
        formData.append(key, '1');
      } else if (value === false) {
        formData.append(key, '0');
      } else {
        formData.append(key, `${value}`);
      }
    };

    Object.entries(data).forEach(([key, value]) => {
      appendFormData(formData, key, value);
    });

    return formData;
  }

  public static GetHttpParams(data: ApiDataModel): HttpParams {
    let params = new HttpParams();

    const appendParams = (params: HttpParams, key: string, value: ApiDataType | ApiDataModel): HttpParams => {
      if (value === null || value === undefined || value instanceof File) {
        return params;
      }

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (Array.isArray(item) || typeof item === 'object') {
            params = appendParams(params, `${key}[${index}]`, item);
          } else {
            params = params.append(`${key}[${index}]`, `${item}`);
          }
        });
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([itemKey, itemValue]) => {
          params = appendParams(params, `${key}[${itemKey}]`, itemValue);
        });
      } else if (value === true) {
        params = params.append(key, '1');
      } else if (value === false) {
        params = params.append(key, '0');
      } else {
        params = params.append(key, `${value}`);
      }

      return params;
    };

    Object.entries(data).forEach(([key, value]) => {
      params = appendParams(params, key, value);
    });

    return params;
  }
}
