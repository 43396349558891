// Angular
import { Component, OnInit } from '@angular/core';
// Models
import { ApiDataModel, CustomerModel, PaginationMetaModel } from '@data/models';
import { Overlay } from '@libs/core/models';
// Services
import { CustomerService } from '@data/services/customer.service';
// External
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-customers-modal',
  templateUrl: './customers-modal.component.html'
})
export class CustomersModalComponent implements OnInit {

  public query: string;
  public regions: Array<string>;

  public customers: Array<CustomerModel>;
  public pagination: PaginationMetaModel;

  public loading: boolean;

  private searchDebounceTimeout: number | undefined;

  constructor(private overlay: Overlay<CustomersModalComponent>, private customerService: CustomerService) {
    this.query = '';
    this.regions = [];

    this.customers = [];
    this.pagination = {
      page: 1,
      perPage: 1,
      count: 1,
      pageCount: 1
    };

    this.loading = false;
  }

  ngOnInit(): void {
    this.getCustomers();
  }

  public getCustomers(page: number = 1): void {
    this.loading = true;

    const q = this.query || '';

    let filter: ApiDataModel = {
      page, q, all: !this.regions.length
    };

    if (!!this.regions.length) {
      filter['regions'] = [...this.regions];
    }

    this.customerService.getCustomers(filter).pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(({ data, meta }) => {
      this.customers = meta.page !== 1 ? [...this.customers, ...data] : data;
      this.pagination = meta;
    });
  }

  public searchHandler(): void {
    window.clearTimeout(this.searchDebounceTimeout);

    this.searchDebounceTimeout = window.setTimeout(() => {
      this.customers = [];
      this.getCustomers();
    }, 250);
  }

  public selectCustomer(customer: CustomerModel): void {
    this.overlay.dismiss(customer);
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}
