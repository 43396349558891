// Angular
import { Injectable } from '@angular/core';
// Models
import { RegionModel } from '@data/models';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RegionsService {

  public allRegions: Array<RegionModel>;
  public regions: Observable<Array<RegionModel>>;

  public regionsSubject: BehaviorSubject<Array<RegionModel>>;

  constructor() {
    this.allRegions = [];

    this.regionsSubject = new BehaviorSubject<Array<RegionModel>>([]);
    this.regions = this.regionsSubject.asObservable();
  }

  public setRegions(regions: Array<RegionModel>): void {
    this.regionsSubject.next(regions);
  }

  public getRegions(): Observable<Array<string>> {
    return this.regions.pipe(
      map(regions => regions.map(({ id }) => id))
    );
  }
}
