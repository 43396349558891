<div class="pb-safe">
  <div class="flex items-center py-2 px-4 bg-white shadow xl:hidden">
    <div class="-ml-2 mr-2">
      <button class="button-icon" aria-label="toggle menu" type="button" (click)="toggleMenu()"><svg class="w-6 h-6 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2"><line fill="none" stroke-miterlimit="10" x1="1" y1="12" x2="23" y2="12"></line><line fill="none" stroke-miterlimit="10" x1="1" y1="5" x2="23" y2="5"></line><line fill="none" stroke-miterlimit="10" x1="1" y1="19" x2="12" y2="19"></line></g></svg></button>
    </div>
    <p class="inline-block">
      <a routerLink="/" class="inline-block align-middle transition hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"><img class="w-auto h-10" src="/images/logo.svg" alt="logo"></a>
    </p>
  </div>
  <nav class="fixed z-50 inset-0 pointer-events-none xl:block" [class.hidden]="!visibleMenu">
    <div class="absolute inset-0 bg-gray-900 bg-opacity-20 pointer-events-auto xl:hidden" role="button" (click)="toggleMenu()"></div>
    <div class="absolute top-0 left-0 flex flex-col w-64 h-full pb-safe bg-white shadow-md pointer-events-auto xl:border-r xl:border-gray-100 xl:shadow-none">
      <div class="flex items-center flex-shrink-0 py-2 px-4 border-b border-gray-100 xl:hidden">
        <div class="flex-1">
          <p class="font-semibold text-base text-gray-900">Menu</p>
        </div>
        <div class="flex-shrink-0 -mr-3">
          <button class="button-icon" aria-label="close" type="button" (click)="toggleMenu()"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></svg></button>
        </div>
      </div>
      <div class="hidden xl:block xl:flex-shrink-0 xl:py-3 xl:px-4">
        <p class="inline-block"><a routerLink="/" class="inline-block align-middle transition hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"><img class="w-auto h-10" src="/images/logo.svg" alt="logo"></a></p>
      </div>
      <div *ngIf="allBusinessUnits.length > 1 || allRegions.length > 1" class="py-3 px-2 border-b border-gray-100">
        <button *ngIf="regions | async as regions" class="flex items-center gap-5 w-full py-2 px-3 rounded-lg transition-colors hover:bg-gray-50 focus:outline-none" [ngClass]="{ 'hidden': allRegions.length <= 1 }" type="button" (click)="changeRegions()">
          <span class="block flex-1 text-left space-y-1">
            <span class="block text-xs text-gray-500">REGION</span>
            <span *ngIf="!regions.length || regions.length === allRegions.length" class="block font-semibold text-sm text-gray-900">{{ 'shared.all' | translate }}</span>
            <span *ngIf="!!regions.length && regions.length !== allRegions.length" class="block font-semibold text-sm text-gray-900 truncate"><span *ngFor="let region of regions; last as last;">{{ region.name }}<span *ngIf="!last">, </span></span></span>
          </span>
          <span class="block flex-shrink-0 text-gray-500"><svg class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM13.9 7H12C11.9 5.5 11.6 4.1 11.2 2.9C12.6 3.8 13.6 5.3 13.9 7ZM8 14C7.4 14 6.2 12.1 6 9H10C9.8 12.1 8.6 14 8 14ZM6 7C6.2 3.9 7.3 2 8 2C8.7 2 9.8 3.9 10 7H6ZM4.9 2.9C4.4 4.1 4.1 5.5 4 7H2.1C2.4 5.3 3.4 3.8 4.9 2.9ZM2.1 9H4C4.1 10.5 4.4 11.9 4.8 13.1C3.4 12.2 2.4 10.7 2.1 9ZM11.1 13.1C11.6 11.9 11.8 10.5 11.9 9H13.8C13.6 10.7 12.6 12.2 11.1 13.1Z"/></svg></span>
        </button>
        <button *ngIf="businessUnits | async as businessUnits" class="flex items-center gap-5 w-full py-2 px-3 rounded-lg transition-colors hover:bg-gray-50 focus:outline-none" [ngClass]="{ 'hidden': allBusinessUnits.length <= 1 }" type="button" (click)="changeBusinessUnits()">
          <span class="block flex-1 text-left space-y-1">
            <span class="block text-xs text-gray-500">BUSINESS UNIT</span>
            <span *ngIf="!businessUnits.length || businessUnits.length === allBusinessUnits.length" class="block font-semibold text-sm text-gray-900">{{ 'shared.all' | translate }}</span>
            <span *ngIf="!!businessUnits.length && businessUnits.length !== allBusinessUnits.length" class="block font-semibold text-sm text-gray-900 truncate"><span *ngFor="let businessUnit of businessUnits; last as last;">{{ businessUnit.name }}<span *ngIf="!last">, </span></span></span>
          </span>
          <span class="block flex-shrink-0 text-gray-500"><svg class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M15.2 2.0202L5.2 0.0202041C5.05469 -0.00945661 4.90461 -0.00642645 4.76062 0.0290751C4.61662 0.0645767 4.48233 0.131661 4.36747 0.225468C4.2526 0.319275 4.16004 0.437457 4.09648 0.571452C4.03293 0.705447 3.99997 0.8519 4 1.0002V6.0002H1C0.734784 6.0002 0.48043 6.10556 0.292893 6.2931C0.105357 6.48063 0 6.73499 0 7.0002L0 15.0002C0 15.2654 0.105357 15.5198 0.292893 15.7073C0.48043 15.8948 0.734784 16.0002 1 16.0002H15C15.2652 16.0002 15.5196 15.8948 15.7071 15.7073C15.8946 15.5198 16 15.2654 16 15.0002V3.0002C16 2.76967 15.9204 2.54621 15.7747 2.36762C15.6289 2.18904 15.4259 2.06631 15.2 2.0202ZM6 14.0002H2V12.0002H6V14.0002ZM6 10.0002H2V8.0002H6V10.0002ZM14 14.0002H8V7.0002C8 6.73499 7.89464 6.48063 7.70711 6.2931C7.51957 6.10556 7.26522 6.0002 7 6.0002H6V2.2202L14 3.8202V14.0002Z"/><path d="M12 6H10V12H12V6Z"/></svg></span>
        </button>
      </div>
      <div class="flex-1 py-4 overflow-y-auto xl:py-5">
        <ul class="space-y-2">
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" [routerLinkActiveOptions]="{ exact: true }" routerLink="/" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="1" width="7" height="12" fill="none"></rect><rect x="3" y="17" width="7" height="6" fill="none"></rect><rect x="14" y="1" width="7" height="6" fill="none"></rect><rect x="14" y="11" width="7" height="12" fill="none"></rect></svg></span><span>{{ 'menu.dashboard' | translate }}</span></a></li>
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/devices" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="11.5" x2="12" y2="22.5" stroke-linecap="butt"></line><polyline points="3 6.5 12 11.5 21 6.5" fill="none" stroke-linecap="butt"></polyline><polygon points="21 17.5 21 6.5 12 1.5 3 6.5 3 17.5 12 22.5 21 17.5" fill="none" stroke-linecap="butt"></polygon></svg></span><span>{{ 'menu.devices' | translate }}</span></a></li>
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/orders" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><polyline points="17,2 22,2 22,23 2,23 2,2 7,2" fill="none"></polyline><polyline points=" 9,15 11,17 15,13" fill="none"></polyline><path d="M7,1v6h3 c0-1.105,0.895-2,2-2s2,0.895,2,2h3V1H7z" fill="none"></path></svg></span><span>{{ 'menu.orders' | translate }}</span></a></li>
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/events" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="1" x2="12" y2="23"></line><rect x="17" y="2" width="6" height="6" fill="none"></rect><line x1="14" y1="5" x2="12" y2="5"></line><rect x="17" y="16" width="6" height="6" fill="none"></rect><line x1="14" y1="19" x2="12" y2="19"></line><rect x="1" y="9" width="6" height="6" fill="none"></rect><line x1="10" y1="12" x2="12" y2="12"></line></svg></span><span>{{ 'menu.events' | translate }}</span></a></li>
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/customers" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><path d="M19,21h4V16.677a1,1,0,0,0-.629-.928l-3.742-1.5A1,1,0,0,1,18,13.323v-.878A3.982,3.982,0,0,0,20,9V7a4,4,0,0,0-6-3.465" fill="none"></path><path d="M14.371,16.749l-3.742-1.5A1,1,0,0,1,10,14.323v-.878A3.982,3.982,0,0,0,12,10V8A4,4,0,0,0,4,8v2a3.982,3.982,0,0,0,2,3.445v.878a1,1,0,0,1-.629.928l-3.742,1.5A1,1,0,0,0,1,17.677V21H15V17.677A1,1,0,0,0,14.371,16.749Z" fill="none"></path></svg></span><span>{{ 'menu.clients' | translate }}</span></a></li>
          <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/map" (click)="toggleMenu()"><span class="flex-shrink-0 mr-3"><svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><path d="M19,8 c0,4.32-7,11.375-7,11.375S5,12.32,5,8c0-4.43,3.617-7,7-7S19,3.57,19,8z" fill="none"></path><circle cx="12" cy="8" r="2" fill="none"></circle><path d="M18,17.485 c3.01,0.535,5,1.462,5,2.515c0,1.657-4.925,3-11,3S1,21.657,1,20c0-1.053,1.99-1.98,5-2.515" fill="none" stroke-linecap="butt"></path></svg></span><span>{{ 'menu.map' | translate }}</span></a></li>
        </ul>
        <div *appIfRole="['ROLE_ADMIN']" class="mt-5">
          <p class="mb-2 mx-3 pb-2 font-semibold text-xs text-gray-500 border-b border-gray-100">{{ 'menu.administrator' | translate | uppercase }}</p>
          <ul class="space-y-2">
            <li class="mx-3 text-gray-500"><a class="flex items-center w-full h-10 py-2 px-3 font-semibold rounded transition hover:text-gray-900 hover:bg-gray-50" [routerLinkActive]="['text-blue-800', 'bg-blue-100', 'hover:bg-blue-100', 'hover:text-blue-800']" routerLink="/accounts" (click)="toggleMenu()"><svg class="w-6 h-6 mr-3 stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" stroke-miterlimit="10"><path d="M14,19l2.95,2.95A3.5,3.5,0,0,0,21.9,22l.051-.051h0A3.5,3.5,0,0,0,22,17l-.051-.051L20,15"></path><polyline points="11.491 8.866 4.203 1.578 1.661 4.12 8.779 11.238" stroke-linecap="butt"></polyline><path d="M22.678,4.922,19.6,7.987l-3.6-3.576,3.08-3.066a4.214,4.214,0,0,0-2.259-.307,5.615,5.615,0,0,0-5.133,5.723A4.223,4.223,0,0,0,12,8.4L2.145,17.083a3.419,3.419,0,0,0-.276,4.827c.023.027.047.052.071.078h0a3.286,3.286,0,0,0,4.647.1,3.232,3.232,0,0,0,.281-.3l8.726-9.81a6.717,6.717,0,0,0,2.875.2A5.687,5.687,0,0,0,22.78,8.192,5.088,5.088,0,0,0,22.678,4.922Z"></path></g></svg><span>{{ 'menu.accounts' | translate }}</span></a></li>
          </ul>
        </div>
      </div>
      <div class="p-4 border-t border-gray-100">
        <div dropdown #accountDropdown="dropdown" class="relative">
          <button class="flex items-center gap-2 w-full p-2 text-gray-500 text-left bg-white rounded focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-offset-2" type="button" (click)="accountDropdown.toggleDropdown()">
            <span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg"><path d="M19,20.486v-0.745 c0-1.077-0.577-2.071-1.512-2.605l-3.219-1.842" stroke-width="2" fill="none"/><path d="M9.727,15.292l-3.215,1.844 C5.577,17.67,5,18.664,5,19.741v0.745" stroke-width="2" fill="none"/><path d="M12,16L12,16 c-2.209,0-4-1.791-4-4v-2c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4v2C16,14.209,14.209,16,12,16z" stroke-width="2" fill="none"/><circle cx="12" cy="12" r="11" stroke-width="2" fill="none"/></svg></span>
            <span class="flex-1 font-semibold text-gray-900">{{ account.firstName }} {{ account.lastName }}</span>
            <span class="flex-shrink-0"><svg class="w-4 h-4 fill-current transform transition-transform" [ngClass]="{ 'rotate-180': accountDropdown.visible }" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.99992 11C7.73472 10.9999 7.48041 10.8945 7.29292 10.707L3.29292 6.70699C3.11076 6.51839 3.00997 6.26579 3.01224 6.00359C3.01452 5.74139 3.11969 5.49058 3.3051 5.30517C3.49051 5.11976 3.74132 5.01459 4.00352 5.01232C4.26571 5.01004 4.51832 5.11083 4.70692 5.29299L7.99992 8.58599L11.2929 5.29299C11.3852 5.19748 11.4955 5.1213 11.6175 5.06889C11.7395 5.01648 11.8707 4.98889 12.0035 4.98774C12.1363 4.98659 12.268 5.01189 12.3909 5.06217C12.5138 5.11245 12.6254 5.1867 12.7193 5.28059C12.8132 5.37449 12.8875 5.48614 12.9377 5.60904C12.988 5.73193 13.0133 5.86361 13.0122 5.99639C13.011 6.12917 12.9834 6.26039 12.931 6.38239C12.8786 6.5044 12.8024 6.61474 12.7069 6.70699L8.70692 10.707C8.51943 10.8945 8.26511 10.9999 7.99992 11Z"/></svg></span>
          </button>
          <div *ngIf="accountDropdown.visible" class="absolute z-50 bottom-full left-0 min-w-full mb-2 bg-white rounded-2xl shadow">
            <div class="flex items-center gap-4 p-4">
              <div class="flex-shrink-0 text-gray-900"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.13989 20.599C5.78889 17.404 8.61389 15 11.9999 15C15.3859 15 18.2049 17.399 18.8579 20.589" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
              <div class="flex-1">
                <p class="font-semibold text-gray-900">{{ account.firstName }} {{ account.lastName }}</p>
                <p class="text-sm text-gray-600">{{ account.email }}</p>
              </div>
            </div>
            <div class="p-3 border-t border-gray-100" (click)="accountDropdown.hideDropdown()">
              <button class="button button-clear w-full" type="button" (click)="changePassword()"><span class="flex-shrink-0 mr-2"><svg class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7ZM8 13C7.70333 13 7.41332 12.912 7.16664 12.7472C6.91997 12.5824 6.72771 12.3481 6.61418 12.074C6.50065 11.7999 6.47094 11.4983 6.52882 11.2074C6.5867 10.9164 6.72956 10.6491 6.93934 10.4393C7.14912 10.2296 7.41639 10.0867 7.70736 10.0288C7.99834 9.97094 8.29994 10.0006 8.57403 10.1142C8.84811 10.2277 9.08238 10.42 9.2472 10.6666C9.41203 10.9133 9.5 11.2033 9.5 11.5C9.5 11.8978 9.34196 12.2794 9.06066 12.5607C8.77936 12.842 8.39782 13 8 13Z"/><path d="M6 6.00001V4.00001C5.99967 3.528 6.16628 3.07108 6.47038 2.71008C6.77448 2.34908 7.19646 2.10727 7.66166 2.02742C8.12687 1.94757 8.60533 2.03483 9.0124 2.27376C9.41947 2.51269 9.72892 2.8879 9.886 3.33301C9.92973 3.45684 9.99742 3.57085 10.0852 3.66853C10.173 3.76621 10.2792 3.84563 10.3977 3.90228C10.5162 3.95892 10.6446 3.99167 10.7758 3.99866C10.9069 4.00565 11.0382 3.98674 11.162 3.94301C11.2858 3.89928 11.3998 3.83158 11.4975 3.74379C11.5952 3.656 11.6746 3.54983 11.7313 3.43134C11.7879 3.31286 11.8207 3.18437 11.8277 3.05323C11.8346 2.92208 11.8157 2.79084 11.772 2.66701C11.4579 1.77687 10.8391 1.02651 10.025 0.548641C9.21098 0.0707686 8.25417 -0.103824 7.32382 0.0557437C6.39347 0.215311 5.54952 0.698757 4.94125 1.42057C4.33297 2.14239 3.99957 3.05607 4 4.00001V6.00001H6Z"/></svg></span><span class="flex-1 text-left">{{ 'menu.password' | translate }}</span></button>
              <button class="button button-clear w-full" type="button" (click)="logout()"><span class="flex-shrink-0 mr-2"><svg class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14 0H9C8.46957 0 7.96086 0.210714 7.58579 0.585786C7.21071 0.960859 7 1.46957 7 2V5H9V2H14V14H9V11H7V14C7 14.5304 7.21071 15.0391 7.58579 15.4142C7.96086 15.7893 8.46957 16 9 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0Z"/><path d="M10 8.99998C10.2652 8.99998 10.5196 8.89462 10.7071 8.70709C10.8947 8.51955 11 8.2652 11 7.99998C11 7.73476 10.8947 7.48041 10.7071 7.29287C10.5196 7.10534 10.2652 6.99998 10 6.99998H5.00003V4.49998C4.99993 4.40399 4.97221 4.31006 4.92017 4.22941C4.86813 4.14876 4.79397 4.08478 4.70656 4.04514C4.61914 4.00549 4.52216 3.99184 4.4272 4.00582C4.33224 4.0198 4.24331 4.06082 4.17103 4.12398L0.171029 7.62398C0.117492 7.67091 0.0745933 7.72873 0.0452035 7.79357C0.0158138 7.85841 0.000610352 7.92879 0.000610352 7.99998C0.000610352 8.07117 0.0158138 8.14154 0.0452035 8.20639C0.0745933 8.27123 0.117492 8.32905 0.171029 8.37598L4.17103 11.876C4.26207 11.9558 4.37897 11.9998 4.50003 12C4.63264 12 4.75981 11.9473 4.85358 11.8535C4.94735 11.7598 5.00003 11.6326 5.00003 11.5V8.99998H10Z"/></svg></span><span class="flex-1 text-left">{{ 'menu.logout' | translate }}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <main class="min-h-screen xl:ml-64">
    <router-outlet></router-outlet>
  </main>
</div>
