// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ApiDataModel, DashboardStatsModel } from '../models';
// Services
import { HelperService } from '../utils/helper.service';
// External
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getDashboardStats(filter: ApiDataModel): Observable<DashboardStatsModel> {
    const params = HelperService.GetHttpParams(filter);
    return this.httpClient.get<DashboardStatsModel>(`${this.apiUrl}/app-admin/v1/dashboard/stats`, { params });
  }
}
