// Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';
// Components
import { AccountPasswordModalComponent } from '../../shared/components/account-password-modal';
import { BusinessUnitsModalComponent } from '../../shared/components/business-units-modal';
import { RegionsModalComponent } from '../../shared/components/regions-modal';
// Models
import { AccountModel, BusinessUnitModel, RegionModel } from '@data/models';
// Services
import { AuthService } from '@data/services/auth.service';
import { BusinessUnitsService } from '@data/services/business-units.service';
import { AccountService } from '@data/services/account.service';
import { EnumService } from '@data/services/enum.service';
import { RegionsService } from '@data/services/regions.service';
import { OverlayService } from '@libs/core/services/overlay.service';
// External
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent {

  public account: AccountModel;
  public allBusinessUnits: Array<BusinessUnitModel>;
  public businessUnits: Observable<Array<BusinessUnitModel>>;
  public allRegions: Array<RegionModel>;
  public regions: Observable<Array<RegionModel>>;

  public visibleMenu: boolean;

  constructor(private router: Router,
              private authService: AuthService,
              private businessUnitsService: BusinessUnitsService,
              private accountService: AccountService,
              private enumService: EnumService,
              private regionsService: RegionsService,
              private overlayService: OverlayService) {
    this.account = this.accountService.getCurrentAccount()!;

    this.allBusinessUnits = [...this.account.businessUnits];
    this.businessUnitsService.allBusinessUnits = this.allBusinessUnits;
    this.businessUnits = this.businessUnitsService.businessUnits;

    this.allRegions = [...this.account.regions];
    this.regionsService.allRegions = this.allRegions;
    this.regions = this.regionsService.regions;

    this.visibleMenu = false;

    if (!this.allBusinessUnits.length || !this.allRegions.length) {
      this.enumService.getEnums()
        .subscribe(enums => {
          const { businessUnits, regions } = enums!;
          if (!this.allBusinessUnits.length) {
            this.allBusinessUnits = businessUnits;
            this.businessUnitsService.allBusinessUnits = this.allBusinessUnits;
          }

          if (!this.allRegions.length) {
            this.allRegions = regions;
            this.regionsService.allRegions = this.allRegions;
          }
        });
    }
  }

  public toggleMenu(): void {
    this.visibleMenu = !this.visibleMenu;
  }

  public changeRegions(): void {
    const overlay = this.overlayService.create(RegionsModalComponent);
    overlay.onDismiss.pipe(
      first(data => !!data)
    ).subscribe({
      next: regions => {
        this.regionsService.setRegions([...regions]);
      }
    });
    overlay.present();
  }

  public changeBusinessUnits(): void {
    const overlay = this.overlayService.create(BusinessUnitsModalComponent);
    overlay.onDismiss.pipe(
      first(data => !!data)
    ).subscribe({
      next: businessUnits => {
        this.businessUnitsService.setBusinessUnits([...businessUnits]);
      }
    });
    overlay.present();
  }

  public changePassword(): void {
    const overlay = this.overlayService.create(AccountPasswordModalComponent);
    overlay.instance.account = this.account;
    overlay.present();
  }

  public logout(): void {
    this.router.navigate(['/auth/login'])
      .then(() => {
        this.accountService.setCurrentAccount(null);
        this.businessUnitsService.setBusinessUnits([]);
        this.regionsService.setRegions([]);
        this.authService.logout();
      });
  }
}
