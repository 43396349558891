// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Components
import { AccountPasswordModalComponent } from './components/account-password-modal';
import { BusinessUnitsModalComponent } from './components/business-units-modal';
import { CustomersModalComponent } from './components/customers-modal';
import { DeviceActivityDrawerComponent } from './components/device-activity-drawer';
import { DeviceActivityVerifyModalComponent } from './components/device-activity-verify-modal';
import { DevicesModalComponent } from './components/devices-modal';
import { RegionsModalComponent } from './components/regions-modal';
import { SearchComponent } from './components/search';
// Directives
import { IfRoleDirective } from './directives/if-role';
import { OpenDeviceActivityDrawerDirective } from './directives/open-device-activity-drawer';
// Pipes
import { BusinessUnitsPipe } from './pipes/business-units';
import { CountryEmojiPipe } from './pipes/country-emoji';
import { DiffDaysPipe } from './pipes/diff-days';
import { InspectionDateStatusPipe } from './pipes/inspection-date-status';
import { PaginationPipe } from './pipes/pagination';
// Libraries
import { CoreModule } from '@libs/core/core.module';
// External
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@libs/form/form.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    TranslateModule,
    FormModule
  ],
  declarations: [
    AccountPasswordModalComponent,
    BusinessUnitsModalComponent,
    CustomersModalComponent,
    DeviceActivityDrawerComponent,
    DeviceActivityVerifyModalComponent,
    DevicesModalComponent,
    RegionsModalComponent,
    SearchComponent,
    IfRoleDirective,
    OpenDeviceActivityDrawerDirective,
    BusinessUnitsPipe,
    CountryEmojiPipe,
    DiffDaysPipe,
    InspectionDateStatusPipe,
    PaginationPipe
  ],
  exports: [
    AccountPasswordModalComponent,
    BusinessUnitsModalComponent,
    CustomersModalComponent,
    DeviceActivityDrawerComponent,
    DeviceActivityVerifyModalComponent,
    DevicesModalComponent,
    RegionsModalComponent,
    SearchComponent,
    IfRoleDirective,
    OpenDeviceActivityDrawerDirective,
    BusinessUnitsPipe,
    CountryEmojiPipe,
    DiffDaysPipe,
    InspectionDateStatusPipe,
    PaginationPipe
  ]
})
export class SharedModule {

  constructor() {
  }
}
