// Angular
import { Component } from '@angular/core';
// Models
import { Overlay } from '@libs/core/models';
import { RegionModel } from '@data/models';
// Services
import { RegionsService } from '@data/services/regions.service';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-regions-modal',
  templateUrl: './regions-modal.component.html'
})
export class RegionsModalComponent {

  public filter: BehaviorSubject<{ q: string; }>;
  public regions: Observable<Array<RegionModel>>;
  public allRegions: Array<RegionModel>;
  public selectedRegions: Array<RegionModel>;

  constructor(private overlay: Overlay<RegionsModalComponent>,
              private regionsService: RegionsService) {
    this.allRegions = [...this.regionsService.allRegions];
    this.selectedRegions = [...this.regionsService.regionsSubject.value];

    this.filter = new BehaviorSubject({ q: '' });
    this.regions = this.filter.pipe(
      map(({ q }) => this.allRegions.filter(({ name }) => {
        return !q || name.toLowerCase().includes(q.toLowerCase());
      }))
    );
  }

  public isSelected(region: RegionModel): boolean {
    return this.selectedRegions.some(({ id }) => id === region.id);
  }

  public selectRegion(region: RegionModel): void {
    const regionIndex = this.selectedRegions.findIndex(({ id }) => id === region.id);
    if (regionIndex > -1) {
      this.selectedRegions.splice(regionIndex, 1);
    } else {
      this.selectedRegions.push(region);
    }
  }

  public selectAllRegions(): void {
    if (this.selectedRegions.length == this.allRegions.length) {
      this.selectedRegions = [];
    } else {
      this.selectedRegions = [...this.allRegions];
    }
  }

  public searchHandler(q: string): void {
    this.filter.next({ q });
  }

  public selectHandler(): void {
    this.overlay.dismiss([...this.selectedRegions]);
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}
