// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ApiDataModel, ProtocolModel } from '../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class ProtocolService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public createProtocol(data: ApiDataModel): Observable<ProtocolModel> {
    return this.httpClient.post<ProtocolModel>(`${this.apiUrl}/app-admin/v1/protocols`, data);
  }

  public editProtocol(id: string, data: ApiDataModel): Observable<ProtocolModel> {
    return this.httpClient.post<ProtocolModel>(`${this.apiUrl}/app-admin/v1/protocols/${id}`, data);
  }

  public getProtocol(id: string): Observable<ProtocolModel> {
    return this.httpClient.get<ProtocolModel>(`${this.apiUrl}/app-admin/v1/protocols/${id}`);
  }

  public acceptanceProtocol(id: string, data: ApiDataModel): Observable<ProtocolModel> {
    return this.httpClient.post<ProtocolModel>(`${this.apiUrl}/app-admin/v1/protocols/${id}/acceptance`, data);
  }

  public downloadProtocol(id: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-admin/v1/protocols/${id}/pdf`);
  }
}
