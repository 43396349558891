// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryEmoji'
})
export class CountryEmojiPipe implements PipeTransform {

  constructor() {
  }

  public transform(countryCode: string): string {
    const codePoints = [...countryCode.toUpperCase()].map(char => {
      const codePoint = char.codePointAt(0);
      const offset = 127397;

      if (codePoint !== undefined) {
        return codePoint + offset;
      } else {
        return 0;
      }
    });

    return String.fromCodePoint(...codePoints);
  }
}
