<!-- modal -->
<div class="fixed z-50 inset-0 bg-gray-900 bg-opacity-10 backdrop-blur-sm overflow-y-auto overscroll-y-contain">
  <div class="flex flex-col items-center justify-end relative min-h-full xl:justify-center xl:py-20">
    <div class="absolute inset-0" role="button" (click)="closeHandler()"></div>
    <div class="relative w-full p-safe bg-white xl:max-w-2xl xl:mx-auto xl:rounded-2xl xl:shadow-xl">
      <header class="flex items-center justify-between gap-4 py-3 px-4 border-b border-gray-100">
        <div class="flex-shrink-0 w-10 h-10"></div>
        <p class="flex-1 font-semibold text-gray-900 text-center">Business Unit</p>
        <div class="flex-shrink-0 w-10 h-10">
          <button class="button-icon" aria-label="close" type="button" (click)="closeHandler()"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></svg></button>
        </div>
      </header>
      <div class="p-6">
        <form *ngIf="filter | async as filter" class="relative" action="" autocomplete="off">
          <input [ngModel]="filter.q" class="form-control h-12 py-3 px-10 rounded-lg bg-transparent" name="query" [placeholder]="'Szukaj…'" type="search" (ngModelChange)="searchHandler($event)">
          <button class="flex items-center justify-center absolute top-0 left-0 z-10 py-4 px-3 text-gray-600 pointer-events-none" tabindex="-1" aria-label="search" type="submit">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M15.707,13.293L13,10.586c0.63-1.05,1-2.275,1-3.586c0-3.86-3.141-7-7-7S0,3.14,0,7s3.141,7,7,7 c1.312,0,2.536-0.369,3.586-1l2.707,2.707C13.488,15.902,13.744,16,14,16s0.512-0.098,0.707-0.293l1-1 C16.098,14.316,16.098,13.684,15.707,13.293z M7,12c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S9.761,12,7,12z"></path></svg>
          </button>
          <button *ngIf="!!filter.q" class="flex items-center justify-center absolute z-10 top-0 right-0 py-4 px-3 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none" tabindex="-1" aria-label="reset" type="button" (click)="searchHandler('')">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11.5,10.1l-1.4,1.4L8,9.4l-2.1,2.1l-1.4-1.4L6.6,8 L4.5,5.9l1.4-1.4L8,6.6l2.1-2.1l1.4,1.4L9.4,8L11.5,10.1z"></path></svg>
          </button>
        </form>
        <div class="relative mt-4">
          <div class="absolute top-3 left-3">
            <input id="business-unit-all" class="form-check" [ngModel]="selectedBusinessUnits.length === allBusinessUnits.length" [ngModelOptions]="{ standalone: true }" [indeterminate]="!!selectedBusinessUnits.length && selectedBusinessUnits.length !== allBusinessUnits.length" [checked]="selectedBusinessUnits.length === allBusinessUnits.length" (ngModelChange)="selectAllBusinessUnits()" type="checkbox">
          </div>
          <label class="block text-sm text-gray-900 py-3.5 pl-12 pr-3 rounded-lg transition-colors hover:bg-gray-50" [for]="'business-unit-all'">Zaznacz wszystkie</label>
        </div>
        <div *ngIf="businessUnits | async as businessUnits" class="mt-2 pt-2 space-y-2 border-t border-gray-100">
          <div *ngFor="let businessUnit of businessUnits" class="relative">
            <div class="absolute top-3 left-3">
              <input class="form-check" [ngModel]="isSelected(businessUnit)" [ngModelOptions]="{ standalone: true }" (ngModelChange)="selectBusinessUnit(businessUnit)" name="business-unit" [id]="'business-unit-' + businessUnit.id" type="checkbox">
            </div>
            <label class="block text-sm text-gray-900 py-3.5 pl-12 pr-3 rounded-lg transition-colors hover:bg-gray-50" [for]="'business-unit-' + businessUnit.id">{{ businessUnit.name }}</label>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between py-4 px-6 border-t border-gray-100">
        <p class="flex-1 font-semibold text-sm text-gray-900">Wybrano: {{ selectedBusinessUnits.length }} z {{ allBusinessUnits.length }}</p>
        <div class="flex-shrink-0">
          <button class="button button-solid-blue h-12 py-3.5 px-10 rounded-lg" type="button" (click)="selectHandler()">Zapisz</button>
        </div>
      </div>
    </div>
  </div>
</div>
