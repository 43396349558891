// Models
import { CustomerModel } from './customer.model';
import { AccountModel } from './account.model';
import { DeviceModel, DeviceTypeModel } from './device.model';
import { PresetModel } from './preset.model';
import { SparePartModel } from './spare-part.model';
import { ProtocolAcceptance } from './protocol.model';
import { RegionModel } from './region.model';

export enum OrderAcceptance {
  NOT_SENT,
  SENT,
  ACCEPTED,
  REJECTED
}

export interface OrderDeviceModel {
  id: string;
  condition: 'new' | 'used' | string;
  done: boolean;
  model: DeviceTypeModel;
  device: DeviceModel;
  baxCapNumber: string;
}

export interface OrderActivityModel {
  id: string;
  kind: 'accepted' | 'close' | 'edit' | 'rejected' | 'share' | string;
  account?: AccountModel;
  payload: Array<{
    key: string;
    value: string;
  }>;
  eventDate: string;
  createdAt: string;
}

export interface OrderModel {
  id: string;
  orderNumber: number;
  orderType: 'installation' | 'deinstallation' | 'repair' | string;
  state: 'open' | 'closed' | 'archived' | string;
  region: RegionModel;
  customer: CustomerModel;
  account: AccountModel;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPhone: string;
  contactPersonPosition: string;
  notes: string;
  devices: Array<OrderDeviceModel>;
  presets: Array<PresetModel>;
  spareParts: Array<{
    id: string;
    sparePart: SparePartModel;
    quantity: number;
    partShipment: 'CS' | 'TS' | string;
  }>;
  signedAgreement: boolean;
  agreementType: string;
  agreementNumber: string;
  quoteNumber: string;
  deliveryAddress: string;
  deliveryDate: string;
  installationDate: string;
  deinstallationDate: string;
  eventDate: string;
  eventSource: 'patient' | 'nurse' | 'doctor' | 'unknown' | 'other' | string;
  eventSourceOther: string;
  eventStage: 'afterUse' | 'beforeUse' | 'duringUse' | 'outOfBoxFailure' | 'unknown' | string;
  eventDescription: string;
  eventPatientInjured: boolean;
  eventPatientCondition: string;
  eventOtherProductUsed: string;
  eventPatientWrittenResponse: boolean;
  repairDate: string;
  warranty: string;
  overview: string;
  customPreset: string;
  protocol: {
    id: string;
    acceptance: ProtocolAcceptance;
  };
  acceptance: OrderAcceptance;
  acceptanceComment: string;
  activities: Array<OrderActivityModel>;
  doneInDays: number;
  workingDays: number;
  overdueDays: number;
  dueDate: string;
  createdAt: string;
  stateChangedAt: string;
  closedAt: string;
  updatedAt: string;
}

export interface InstallationOrderDeviceModel {
  condition: 'new' | 'used' | string;
  device?: string;
  model?: string;
  baxCapNumber: string;
}

export interface InstallationOrderSparePartModel {
  sparePart: number;
  quantity: number;
  partShipment: 'CS' | 'TS' | string;
}

export interface CreateInstallationOrderModel {
  customer: string;
  region: string | null;
  signedAgreement: boolean | null;
  agreementType: 'lease' | 'sale' | 'shortTermLease' | 'conference' | string;
  quoteNumber: string;
  agreementNumber: string;
  deliveryAddress: string;
  deliveryDate: string;
  installationDate: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPosition: string;
  contactPersonPhone: string;
  devices: Array<InstallationOrderDeviceModel>;
  spareParts: Array<InstallationOrderSparePartModel>;
  warranty: 'baxter' | '1y' | '2y' | '3y' | 'other' | string;
  overview: 'free' | 'parts_only' | 'paid' | 'other' | string;
  presets: Array<number>;
  customPreset: string;
  notes: string;
}

export interface CreateDeinstallationOrderModel {
  customer: string;
  region: string | null;
  devices: Array<string>;
  deinstallationDate: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPosition: string;
  contactPersonPhone: string;
  notes: string;
}

export interface CreateRepairOrderModel {
  customer: string;
  region: string | null;
  devices: Array<string>;
  eventDate: string;
  eventSource: 'patient' | 'nurse' | 'doctor' | 'unknown' | 'other' | string;
  eventSourceOther: string;
  eventStage: 'afterUse' | 'beforeUse' | 'duringUse' | 'outOfBoxFailure' | 'unknown' | string;
  eventDescription: string;
  eventPatientInjured: boolean | null;
  eventPatientCondition: string;
  eventOtherProductUsed: string;
  eventPatientWrittenResponse: boolean | null;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPosition: string;
  contactPersonPhone: string;
  notes: string;
}

export interface AcceptanceOrderModel {
  acceptance: OrderAcceptance;
  acceptanceComment?: string;
  signature: string;
}
