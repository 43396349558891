// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import {
  AcceptanceOrderModel,
  ApiDataModel,
  CreateDeinstallationOrderModel,
  CreateInstallationOrderModel,
  CreateRepairOrderModel,
  OrderModel,
  PaginationModel
} from '../models';
// Services
import { HelperService } from '../utils/helper.service';
// External
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public createInstallationOrder(data: CreateInstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/installationOrder`, data);
  }

  public editInstallationOrder(id: string, data: CreateInstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/installationOrder/${id}`, data);
  }

  public createDeinstallationOrder(data: CreateDeinstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/deinstallationOrder`, data);
  }

  public editDeinstallationOrder(id: string, data: CreateDeinstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/deinstallationOrder/${id}`, data);
  }

  public createRepairOrder(data: CreateRepairOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/repairOrder`, data);
  }

  public editRepairOrder(id: string, data: CreateRepairOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/repairOrder/${id}`, data);
  }

  public getOrders(filter: ApiDataModel): Observable<PaginationModel<OrderModel>> {
    const params = HelperService.GetHttpParams(filter);
    return this.httpClient.get<PaginationModel<OrderModel>>(`${this.apiUrl}/app-admin/v1/orders`, { params });
  }

  public exportOrders(filter: ApiDataModel): Observable<{ name: string; file: Blob; }> {
    const params = HelperService.GetHttpParams(filter);

    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/orders/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public getOrder(id: string): Observable<OrderModel> {
    return this.httpClient.get<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}`);
  }

  public openOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/open`, null);
  }

  public closeOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/close`, null);
  }

  public archiveOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/archive`, null);
  }

  public acceptanceOrder(id: string, data: AcceptanceOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/acceptance`, data);
  }

  public shareOrder(id: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/orders/${id}/share`, null);
  }
}
