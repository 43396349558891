// Angular
import { Component, Input } from '@angular/core';
// Components
import { DeviceActivityVerifyModalComponent } from '../device-activity-verify-modal';
// Models
import { AccountRole, DeviceActivityModel } from '@data/models';
import { Overlay } from '@libs/core/models';
// Services
import { AccountService } from '@data/services/account.service';
import { OverlayService } from '@libs/core/services/overlay.service';
// Environments
import { environment } from '../../../../../environments/environment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-device-activity-drawer',
  templateUrl: './device-activity-drawer.component.html'
})
export class DeviceActivityDrawerComponent {

  @Input()
  public deviceActivity!: DeviceActivityModel;

  constructor(private overlay: Overlay<DeviceActivityDrawerComponent>, private accountService: AccountService, private overlayService: OverlayService) {
  }

  public get accountIsAdmin(): boolean {
    const { role } = this.accountService.getCurrentAccount()!;
    return role === AccountRole.ADMIN;
  }

  public get googleMapsUrl(): string {
    const location = `${this.deviceActivity.lat},${this.deviceActivity.lng}`;
    return `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(location)}&zoom=10&size=304x152&markers=${encodeURIComponent('color:red|' + location)}&key=${environment.googleApiKey}`;
  }

  public get googleMapsPlaceUrl(): string {
    const location = `${this.deviceActivity.lat},${this.deviceActivity.lng}`;
    // https://developers.google.com/maps/documentation/urls/get-started
    return `https://google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
  }

  public showEventVerifyModal(): void {
    const overlay = this.overlayService.create(DeviceActivityVerifyModalComponent);
    overlay.instance.deviceActivity = this.deviceActivity;
    overlay.onDismiss.pipe(
      filter(deviceActivity => !!deviceActivity)
    ).subscribe(({ confirmed }) => {
      this.deviceActivity.confirmed = confirmed;
    })
    overlay.present();
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}
