// Angular
import { Component } from '@angular/core';
// Models
import { Overlay } from '@libs/core/models';
import { BusinessUnitModel } from '@data/models';
// Services
import { BusinessUnitsService } from '@data/services/business-units.service';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-business-units-modal',
  templateUrl: './business-units-modal.component.html'
})
export class BusinessUnitsModalComponent {

  public filter: BehaviorSubject<{ q: string; }>;
  public businessUnits: Observable<Array<BusinessUnitModel>>;
  public allBusinessUnits: Array<BusinessUnitModel>;
  public selectedBusinessUnits: Array<BusinessUnitModel>;

  constructor(private overlay: Overlay<BusinessUnitsModalComponent>,
              private businessUnitsService: BusinessUnitsService) {
    this.allBusinessUnits = [...this.businessUnitsService.allBusinessUnits];
    this.selectedBusinessUnits = [...this.businessUnitsService.businessUnitsSubject.value];

    this.filter = new BehaviorSubject({ q: '' });
    this.businessUnits = this.filter.pipe(
      map(({ q }) => this.allBusinessUnits.filter(({ name }) => {
        return !q || name.toLowerCase().includes(q.toLowerCase());
      }))
    );
  }

  public isSelected(businessUnit: BusinessUnitModel): boolean {
    return this.selectedBusinessUnits.some(({ id }) => id === businessUnit.id);
  }

  public selectBusinessUnit(businessUnit: BusinessUnitModel): void {
    const businessUnitIndex = this.selectedBusinessUnits.findIndex(({ id }) => id === businessUnit.id);
    if (businessUnitIndex > -1) {
      this.selectedBusinessUnits.splice(businessUnitIndex, 1);
    } else {
      this.selectedBusinessUnits.push(businessUnit);
    }
  }

  public selectAllBusinessUnits(): void {
    if (this.selectedBusinessUnits.length == this.allBusinessUnits.length) {
      this.selectedBusinessUnits = [];
    } else {
      this.selectedBusinessUnits = [...this.allBusinessUnits];
    }
  }

  public searchHandler(q: string): void {
    this.filter.next({ q });
  }

  public selectHandler(): void {
    this.overlay.dismiss([...this.selectedBusinessUnits]);
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}
